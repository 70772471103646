import _ from 'lodash';
import React, { } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    sceneContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary.main
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paragraph: {
        margin: 10,
        maxWidth: 700,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paragraphList: {
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    photoParagraph: {
        margin: 10,
        maxWidth: 700,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    progress: {
        position: 'absolute',
        left: '50%',
        marginLeft: -12,
        marginTop: 12
    }
}));

export default useStyles;
