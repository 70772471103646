import React, {useRef, useEffect, useState} from 'react'
import { SvgIcon } from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

import Colors from '../../Constants/Colors'
import useOnScreen from '../../Helpers/useIntersection'
import anime from "animejs";

const O2GFullLogoNegative = (props = {}) => {
    const { isAnimateEnabled, strokeOpacity = "1", isOVisible = true, is2Visible = true, isGVisible = true } = props
    const ref = useRef(null);
    let animation = useRef(null);
    const theme = useTheme();
    const [randId] = useState(Math.floor(Math.random() * 100000))
    const oPathId = `oPathId-${randId}`;
    const twoPathId = `twoPathId-${randId}`;
    const dashPathId = `dashPathId-${randId}`;
    const gPathId = `gPathId-${randId}`;

    const pathStyle = {
        stroke: props.color || theme.palette.primary.main,
        strokeWidth: 7.4647,
        strokeMiterlimit: 10
    }

    return (
            <SvgIcon
                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="60.43 149.53 403.97 204.73" style="enable-background:new 60.43 149.53 403.97 204.73;" space="preserve"
                {...props}
            >
                <path style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} d="M258.6,217.8c-9.1,0-13.3,4.5-13.3,11.2v2.1h-26.4c1.3,6.3,1.9,13.2,1.9,20.7c0,7.3-0.6,14.1-1.8,20.3    l38.7-26.5c8.4-5.7,14.2-11.2,14.2-16.7C272,222.5,268,217.8,258.6,217.8z M362.1,245.1v32h49.2c-2.2,12.6-8.1,22.7-17.6,30.5    c-9.5,7.8-21.8,11.6-36.7,11.6c-15.2,0-28.8-4.9-40.7-14.7c-2.8-2.3-5.2-4.9-7.3-7.9h-48.5c4.7,11.5,11.5,21.5,20.6,30    c18,17.6,41.1,26.4,69.3,26.4c17.1,0,31.6-3.3,43.3-9.7c6.8-3.7,14.5-10.1,23.1-19.2l4.5,23.9h29.5V245.1H362.1z M421.8,169.9    c-16.4-12.3-38.1-18.5-65.2-18.5c-31.2,0-56.3,9.4-75.2,28.1c-5.5,5.4-10.2,11.4-14.1,17.8c19.1,1.9,32.3,10.4,36.8,22.2    c2.7-6.9,6.3-12.8,10.9-17.7c11-11.7,25.1-17.5,42.2-17.5c9,0,17.1,1.4,24.4,4.3c13.1,5.2,21.3,14.3,24.7,27.4H450    C447.6,197.7,438.2,182.3,421.8,169.9z M298.4,254.4c0-2.9,0.1-5.7,0.2-8.4c-3.9,4.3-9.2,8.2-15.8,12.6l-27.1,17.8h44.8    C299.1,269.9,298.4,262.6,298.4,254.4z M258.6,217.8c-9.1,0-13.3,4.5-13.3,11.2v2.1h-26.4c1.3,6.3,1.9,13.2,1.9,20.7    c0,7.3-0.6,14.1-1.8,20.3l38.7-26.5c8.4-5.7,14.2-11.2,14.2-16.7C272,222.5,268,217.8,258.6,217.8z M209.4,296.5    c-1.2,1.8-2.6,3.5-4,5.2c-10.4,11.7-24.4,17.5-42,17.5c-17.6,0-31.7-5.8-42.2-17.5c-10.5-11.7-15.7-28.3-15.7-49.9    c0-21.6,5.2-38.2,15.7-49.9c10.5-11.7,24.5-17.5,42.2-17.5c17.6,0,31.6,5.9,42,17.6c3.9,4.4,7,9.4,9.4,15.2    c5.5-11.5,19.1-18.9,38.4-20.1c-5.1-9.4-11.8-17.5-20-24.2c-16.1-14.9-39.3-22.3-69.7-22.3c-30.4,0-53.7,7.4-69.7,22.3    c-21.6,17.6-32.3,43.9-32.3,79c0,34.4,10.8,60.7,32.3,79c16.1,14.9,39.3,22.3,69.7,22.3c30.4,0,53.7-7.4,69.7-22.3    c11-9.3,19.1-20.7,24.5-34.3H209.4z"/>
            </SvgIcon>
    );
}

export default O2GFullLogoNegative
