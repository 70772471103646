import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ScreenSizeContext = React.createContext(null);

const ScreenSizeProvider = ({ children }) => {
    const themey = useTheme();
    const [ screenSizes, updateScreenSizes ] = useState({});
    const [ logo, updateLogo ] = useState(window.localStorage.getItem('logo') || 'gratitude');
    const [ theme, updateTheme ] = useState(window.localStorage.getItem('customTheme') || 'Warm Tones');

    const newScreenSizes = {
        isXS: useMediaQuery(themey.breakpoints.down('xs')), // < 959.95,
        isSmall: useMediaQuery(themey.breakpoints.down('sm')), // < 959.95,
        isMedium: useMediaQuery(themey.breakpoints.down( 'md')), // 960 - 1279.95
        isLarge: useMediaQuery(themey.breakpoints.up('lg')) // > 1280
    };

    if(!_.isEqual(screenSizes, newScreenSizes)) {
        setTimeout(() => updateScreenSizes(newScreenSizes), 100);
    }

    return (
        <ScreenSizeContext.Provider
            value={{
                ...screenSizes,
                logo,
                theme,

                updateLogo: (logo) => {
                    window.localStorage.setItem('logo', logo);
                    updateLogo(logo)
                },
                updateTheme: (theme) => {
                    window.localStorage.setItem('customTheme', theme);
                    updateTheme(theme)
                },
            }}
        >
            {children}
        </ScreenSizeContext.Provider>
    );
};

const useScreenSize = () => {
    const Event = useContext(ScreenSizeContext);
    if (Event == null) {
        throw new Error("useScreenSize() called outside of a ScreenSizeProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { ScreenSizeProvider, useScreenSize };
