import React, {useRef, useEffect, useState} from 'react'
import { SvgIcon } from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

import O2GFullLogo from './O2GFullLogo'
import O2GFullLogoNegative from './O2GFullLogoNegative'
import O2GFullLogoHex from './O2GFullLogoHex'
import O2GGratitudeLogo from './O2GGratitudeLogo'

import Colors from '../../Constants/Colors'
import useOnScreen from '../../Helpers/useIntersection'
import anime from "animejs";

import {useScreenSize} from "../../Providers/ScreenSizeProvider";

const O2GFullLogoBasic = (props = {}) => {
    const { logo, updateLogo } = useScreenSize();
    const {
        isAnimateEnabled,
        strokeOpacity = "1",
        isOVisible = true,
        is2Visible = true,
        isGVisible = true,
        logoOverride
    } = props;
    const logoToUse = logoOverride || logo;

    const ref = useRef(null);
    let animation = useRef(null);
    const theme = useTheme();
    // const inViewport = useOnScreen(ref, { updateOnce: true });
    const [randId] = useState(Math.floor(Math.random() * 100000))
    const oPathId = `oPathId-${randId}`;
    const twoPathId = `twoPathId-${randId}`;
    const dashPathId = `dashPathId-${randId}`;
    const gPathId = `gPathId-${randId}`;


    const pathStyle = {
        stroke: props.color ||theme.palette.primary.main,
        strokeWidth: 7.4647,
        strokeMiterlimit: 10
    }

    if (logoToUse === 'negative') {
        return (
            <O2GFullLogoNegative {...props} />
        )
    }

    if (logoToUse === 'original') {
        return (
            <O2GFullLogo {...props} />
        )
    }

    if (logoToUse === 'hex') {
        return (
            <O2GFullLogoHex {...props} />
        )
    }

    if (logoToUse === 'gratitude') {
        return (
            <O2GGratitudeLogo {...props} />
        )
    }

    return (
        <SvgIcon
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="61.97 164.47 399.66 146.26" style="enable-background:new 61.97 164.47 399.66 146.26;" space="preserve"
            {...props}
        >
            {isOVisible && <path style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} d="M135.6,307c-20.8,0-36.7-5.1-47.8-15.3c-14.8-12.5-22.1-30.5-22.1-54.1c0-24,7.4-42,22.1-54.1    c11-10.2,26.9-15.3,47.8-15.3c20.8,0,36.7,5.1,47.8,15.3c14.7,12,22,30.1,22,54.1c0,23.5-7.4,41.6-22,54.1    C172.3,301.9,156.4,307,135.6,307z M164.4,271.8c7.1-8,10.6-19.4,10.6-34.2c0-14.7-3.5-26.1-10.6-34.1c-7.1-8-16.7-12.1-28.7-12.1    s-21.7,4-28.9,12c-7.2,8-10.8,19.4-10.8,34.2c0,14.8,3.6,26.1,10.8,34.2c7.2,8,16.8,12,28.9,12S157.3,279.8,164.4,271.8z"/>}
            { is2Visible && <path style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} d="M277.7,200.6c-3.4-3.6-8.2-5.4-14.4-5.4c-8.6,0-14.4,2.9-17.5,8.6c-1.8,3.3-2.8,8.6-3.2,15.8h-27.4    c0.5-11,2.7-19.8,6.6-26.6c7.5-12.9,20.9-19.3,40-19.3c15.2,0,27.2,3.8,36.2,11.3c9,7.6,13.4,17.6,13.4,30c0,9.5-3.2,18-9.5,25.4    c-4.2,4.9-11,10.4-20.5,16.5l-11.3,7.2c-7.1,4.5-11.9,7.8-14.5,9.8c-2.6,2-4.8,4.4-6.6,7h62.6v22.3h-98.2    c0.3-9.2,2.5-17.7,6.6-25.3c4-8.6,13.5-17.7,28.5-27.3c13-8.4,21.4-14.3,25.2-18c5.9-5.6,8.8-11.8,8.8-18.5    C282.8,208.8,281.1,204.3,277.7,200.6z"/> }
            { isGVisible && <path style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} d="M418.9,300.2c-8,4.4-17.9,6.7-29.7,6.7c-19.3,0-35.1-6-47.5-18.1c-12.9-12.1-19.3-28.6-19.3-49.6    c0-21.2,6.5-38.2,19.5-51c13-12.8,30.2-19.2,51.5-19.2c18.5,0,33.4,4.2,44.6,12.7c11.2,8.4,17.7,19,19.3,31.6h-30    c-2.3-9-7.9-15.2-16.9-18.8c-5-2-10.6-2.9-16.7-2.9c-11.7,0-21.4,4-28.9,12c-7.5,8-11.3,20-11.3,36c0,16.1,4.1,27.5,12.3,34.2    c8.2,6.7,17.5,10,27.9,10c10.2,0,18.6-2.7,25.1-8c6.5-5.3,10.5-12.3,12.1-20.9h-33.7V233h60.7v70.3h-20.2l-3.1-16.4    C428.8,293.2,423.6,297.6,418.9,300.2z"/> }
        </SvgIcon>
    );
}

export default O2GFullLogoBasic
