import Theme from "./Theme";
import CoolTheme from "./CoolTheme";
import WarmTheme from "./WarmTheme";

export default [
    {
        key: 'Cool Tones',
        Theme: CoolTheme
    }, {
        key: 'Warm Tones',
        Theme: WarmTheme
    }, {
        key: 'Original',
        Theme: Theme
    },
]
