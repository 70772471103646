import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef, Suspense } from "react";
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import ReactSpinner from "@material-ui/core/CircularProgress";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from "../Styles/ApplicationUI";

import { Routes } from "../Constants/Routes";
import NavigationDrawer from "../Components/NavigationDrawer";
import { useRouting } from "../Providers/RoutingProvider";
import {useScreenSize} from "../Providers/ScreenSizeProvider";

const NavigationStructure = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { isXS, theme:themeKey } = useScreenSize();
    const [open, setOpen] = useState(false);
    const { currentRoute } = useRouting();
    const RouteRightIcon = _.get(currentRoute, 'RightIcon')

    useEffect(() => {
        if (isXS && open) {
            setOpen(false)
        }
    }, [isXS])

    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                // color={'primaryGradient'}
            >
                <Toolbar
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(true)}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.appBarTitle} variant="h6" noWrap>
                        {_.get(currentRoute, 'appBarTitle', _.get(currentRoute, 'title'))}
                    </Typography>
                    {RouteRightIcon &&
                        <RouteRightIcon />
                    }
                </Toolbar>
            </AppBar>
            <NavigationDrawer open={open} setOpen={setOpen} />
        </React.Fragment>
    )
}

const ApplicationUI = ({ children }) => {
    const classes = useStyles();
    const { currentRoute } = useRouting();

    return (
        <div>
            <CssBaseline />
            <NavigationStructure />
            <main
                className={clsx(classes.content, {
                    //[classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <Switch>
                    {_.map(_.values(Routes), ({ title, path, exact = false, Scene }) => {
                        return (
                            <Route exact={exact} path={path} render={(props) => {
                                return (
                                    <Suspense fallback={() => <ReactSpinner />}>
                                        <Scene {...props} />
                                    </Suspense>
                                )
                            }}/>
                        )
                    })}
                </Switch>
            </main>
        </div>
    );
};

export default ApplicationUI;
