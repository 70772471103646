import _ from 'lodash';
import React, {useState} from "react";
import { animateScroll as scroll } from 'react-scroll';
import {
    Divider,
    Typography
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import clsx from "clsx";

import {useScreenSize} from "../../Providers/ScreenSizeProvider";

import useSceneStyles from "../../Styles/Scene";

import PageTitle from '../../Components/UI/PageTitle';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ReUniteTerms = (props) => {
    const {
        style = {}
    } = props;
    const { isLarge, isMedium, isSmall, isXS } = useScreenSize();
    const sceneClasses = useSceneStyles();
    const theme = useTheme();

    const windowHeight = window.innerHeight - 64;
    const windowWidth = window.innerWidth - 64;

    let titleSize = "h2";
    let pdfScale = 1.5;
    if (isXS) {
        titleSize = "h6";
        pdfScale = .75;
    } else if (isSmall) {
        titleSize = "h6";
        pdfScale = 1
    }

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    return (
        <div
            className={sceneClasses.sceneContainer}
            style={{
                minHeight: 675,
                ...style
            }}>
            <div style={{ textAlign: 'center' }}>
                <Typography
                    style={{
                        // color: theme.palette.secondary.main,
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                        marginBottom: 15
                    }}
                    variant={"h3"}
                >
                    ReUnite Terms of Use
                </Typography>
            </div>
            <div>
                <Document file="/ReUniteTermsOfUse.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                    {
                        Array.apply(null, Array(numPages))
                            .map((x, i)=>i+1)
                            .map(page => <div style={{ margin: 10 }}>
                                <Page
                                    pageNumber={page}
                                    scale={pdfScale}
                                />
                            </div>)
                    }
                </Document>
            </div>
        </div>
    )
}

export default ReUniteTerms
