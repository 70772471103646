import _ from 'lodash';
import React, {useState} from "react";
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import useStyles, { drawerWidth } from "../Styles/ApplicationUI";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Routes from "../Constants/Routes";
import O2GFullLogo from "./SVGIcons/O2GFullLogoBasic";
import {useScreenSize} from "../Providers/ScreenSizeProvider";
import useSceneStyles from "../Styles/Scene";

import Themes from '../Constants/Themes';

let logos = [
    'gratitude',
    'basic',
    'negative',
    // 'original',
    // 'hex'
]

const LogoSelector = (props) => {
    const { logo, updateLogo} = useScreenSize();

    const sceneClasses = useSceneStyles();
    const theme = useTheme();

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Logos</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {logos.map((logoKey) => {
                    return (
                        <O2GFullLogo
                            onClick={() => updateLogo(logoKey)}
                            color={logoKey === logo ? theme.palette.primary.main : theme.palette.primary.light}
                            style={{ height: 52, width: 52, cursor: 'pointer' }}
                            logoOverride={logoKey}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

const ThemeSelector = (props) => {
    const { theme:themeKey, updateTheme } = useScreenSize();

    const sceneClasses = useSceneStyles();
    const themeStyles = useTheme();

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Themes</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {Themes.map((theme) => {
                    return (
                        <Typography
                            style={{ margin: 5, cursor: 'pointer', color: themeKey === theme.key ? themeStyles.palette.primary.main : 'inherit' }}
                            variant={'h5'}
                            onClick={() => updateTheme(theme.key)}
                        >
                            {theme.key}
                        </Typography>
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

const NavigationItem = (props) => {
    const {
        key,
        title,
        navTitle,
        path,
        NavIcon,
        routes,
        isDefaultOpen = false,
        subRouteIndex = 0,
        onClick,
        setOpen,
        index
    } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch(path) || {};
    const [ isOpen, updateIsOpen ] = useState(isDefaultOpen);

    if (!_.isEmpty(routes)) {
        return (
            <React.Fragment>
                <ListItem className={clsx({ [classes.navigationItemSelected]: match.isExact })}
                          button
                          key={title}
                          onClick={() => updateIsOpen(!isOpen)}
                >
                    <ListItemIcon ><NavIcon /></ListItemIcon>
                    <ListItemText primary={navTitle || title} />
                    {isOpen ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItem>
                {isOpen && _.map(routes, (route, index) => {
                    return (
                        <NavigationItem key={`${title}-${index}`} {...route} subRouteIndex={subRouteIndex + 1}/>
                    )
                })}
            </React.Fragment>
        )
    }

    return (
        <ListItem key={key || title}
                  className={clsx({ [classes.navigationItemSelected]: match.isExact })}
                  style={subRouteIndex ? { paddingLeft: 20 * subRouteIndex } : {}}
                  button
                  onClick={() => {
                      setOpen(false)
                      history.push(path)
                  }}
        >
            <ListItemIcon ><NavIcon /></ListItemIcon>
            <ListItemText primary={navTitle || title} />
        </ListItem>
    )
}

const NavigationDrawer = ({ open, setOpen }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <O2GFullLogo style={{ width: 52, height: 52 }} />
                <IconButton onClick={() => setOpen(false)}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                {_.map(Routes, (route, index) => (
                    <NavigationItem key={`route-${index}`} index={index} {...route } setOpen={setOpen} />
                ))}
            </List>
            <LogoSelector />
            <ThemeSelector />
        </Drawer>
    );
};

export default NavigationDrawer;
