import _ from 'lodash';
import React, { lazy } from 'react'
import People from '@material-ui/icons/People';
import Cloud from '@material-ui/icons/Cloud';

import O2GFullLogo from '../Components/SVGIcons/O2GFullLogoBasic'
import ReUniteTerms from "../Scenes/ReUniteTerms/ReUniteTerms";

const Home = lazy(() => import('../Scenes/Home/Home'));
const Team = lazy(() => import('../Scenes/Team/Team'));
const TeamMember = lazy(() => import('../Scenes/TeamMember/TeamMember'));
const AppInstance = lazy(() => import('../Scenes/AppInstance/AppInstance'));
const ReUnitePrivacy = lazy(() => import('../Scenes/ReUnitePrivacy/ReUnitePrivacy'));
const ReUniteBeta = lazy(() => import('../Scenes/ReUniteBeta/ReUniteBeta'));
const Apps = lazy(() => import('../Scenes/Apps/Apps'));

export const Routes = {
    HOME: {
        key: 'HOME',
        title: 'O2G',
        appBarTitle: '',
        navTitle: '',
        path: '/',
        Scene: Home,
        RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={'white'} />),
        NavIcon: (props) => (<O2GFullLogo style={{ width: 30, height: 30 }} {...props} />),
        exact: true
    },
    TEAM: {
        title: 'Meet the Team',
        path: '/team',
        RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
        NavIcon: (props = {}) => (<People style={{ fontSize: 30 }} {...props} />),
        Scene: Team
    },
    TEAM_MEMBER: {
        appBarTitle: '',
        navTitle: '',
        path: '/teamMember/:teamMemberId',
        RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
        Scene: TeamMember,
        exact: false
    },
//     BUTTERFLY_PLAYGROUND: {
//         title: 'Butterfly Playground',
//         path: '/butterflyPlayground',
//         RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
//         NavIcon: (props = {}) => (<Butterfly style={{ width: 30 }} color={Colors.textSelected} {...props} />),
//         Scene: ButterflyPlayground
//     },
//     // LAUNCH_PARTY: {
//     //     title: 'Launch Party',
//     //     path: '/launchParty',
//     //     RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
//     //     NavIcon: (props = {}) => (<CakeRounded style={{ fontSize: 30 }} {...props} />),
//     //     Scene: LaunchParty
//     // },
        TECH: {
            title: 'View the Apps',
            path: '/apps',
            RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
            NavIcon: (props = {}) => (<Cloud style={{ fontSize: 30 }} {...props} />),
            Scene: Apps
        },
        APPS: {
            appBarTitle: '',
            navTitle: '',
            path: '/tech/:appId',
            RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
            Scene: AppInstance,
            exact: false
        },
        REUNITE_BETA: {
            appBarTitle: '',
            navTitle: '',
            path: '/ReUnite/Beta',
            RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
            Scene: ReUniteBeta,
            exact: false
        },
        REUNITE_PRIVACY_POLICY: {
            appBarTitle: '',
            navTitle: '',
            path: '/ReUnite/Privacy',
            RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
            Scene: ReUnitePrivacy,
            exact: false
        },
        REUNITE_TERMS_OF_USE: {
            appBarTitle: '',
            navTitle: '',
            path: '/ReUnite/Terms',
            RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
            Scene: ReUniteTerms,
            exact: false
        },
//     BUTTERFLY_BUILDER: {
//         title: 'Butterfly Builder',
//         path: '/butterflyBuilder',
//         RightIcon: () => (<O2GFullLogo style={{ width: 52, height: 52 }} color={"white"}/>),
//         NavIcon: (props = {}) => (<Butterfly style={{ width: 30 }} color={Colors.textSelected} {...props} />),
//         Scene: ButterflyBuilder
//     }
}

const NavigationRoutes = [
    Routes.HOME,
    Routes.TEAM,
    Routes.TECH,
    // // Routes.LAUNCH_PARTY,
    // Routes.BUTTERFLY_PLAYGROUND,
];

export const findRoute = (path) => {
    return _.find(Routes, (routePath) => {
        let routePathRegExp = new RegExp(`^${_.replace(routePath.path, /(:[a-zA-Z]*)/gi, '.*')}$`)
        return path.match(routePathRegExp)
    })
}

export default NavigationRoutes
