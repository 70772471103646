import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";

import APIRequest from "../Services/shared/APIRequest";
import AsyncDebounce from "../Helpers/Debounce";

const HouseProviderContext = React.createContext(null);

const debounce = new AsyncDebounce();

const HouseProvider = ({ children }) => {
    const [ houses, updateHouses ] = useState([]);
    const [ housesUpdatedAt, updateHousesUpdatedAt ] = useState();
    const [ houseLoadingError, updateHouseLoadingError ] = useState();

    const getHouses = async () => {
        try {
            if (!_.isEmpty(housesUpdatedAt) && new Date() - housesUpdatedAt < 1000 * 60 * 10) return houses
            const houses = await APIRequest.get('/api/houses');
            updateHouseLoadingError();
            updateHouses(houses);
            updateHousesUpdatedAt(new Date());
            return houses
        } catch(err) {
            updateHouseLoadingError(err)
        }
    }

    return (
        <HouseProviderContext.Provider
            value={{
                getHouses: async (args) => debounce.debounce(args, getHouses),
                houses,
                houseLoadingError
            }}
        >
            {children}
        </HouseProviderContext.Provider>
    );
};

const useHouseProvider = () => {
    const Event = useContext(HouseProviderContext);
    if (Event == null) {
        throw new Error("useHouseProvider() called outside of a HouseProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { HouseProvider, useHouseProvider };
