import React, {useRef, useEffect, useState} from 'react'
import { SvgIcon } from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

import Colors from '../../Constants/Colors'
import useOnScreen from '../../Helpers/useIntersection'
import anime from "animejs";

const O2GFullLogo = (props = {}) => {
    const { isAnimateEnabled, strokeOpacity = "1", isOVisible = true, is2Visible = true, isGVisible = true } = props
    const ref = useRef(null);
    let animation = useRef(null);
    const theme = useTheme();
    const inViewport = useOnScreen(ref, { updateOnce: true });
    const [randId] = useState(Math.floor(Math.random() * 100000))
    const oPathId = `oPathId-${randId}`;
    const twoPathId = `twoPathId-${randId}`;
    const dashPathId = `dashPathId-${randId}`;
    const gPathId = `gPathId-${randId}`;

    useEffect(() => {
        if (isAnimateEnabled) {
            let timeline = anime.timeline({
                easing: 'easeInOutCubic',
                duration: 1000,
                autoplay: false,
                begin: () => {
                    document.querySelector(`#${oPathId}`)?.setAttribute("stroke-opacity", 1);
                    document.querySelector(`#${twoPathId}`)?.setAttribute("stroke-opacity", 1);
                    document.querySelector(`#${dashPathId}`)?.setAttribute("stroke-opacity", 1);
                    document.querySelector(`#${gPathId}`)?.setAttribute("stroke-opacity", 1);
                },
            })

            timeline.add({
                targets: `#${oPathId}`,
                strokeDashoffset: [anime.setDashoffset, 0],
            })
            timeline.add({
                targets: `#${oPathId}`,
                fillOpacity: [0, 1],
            }, '-=500')

            timeline.add({
                targets: `#${twoPathId}`,
                strokeDashoffset: [anime.setDashoffset, 0],
            }, '-=1000')
            timeline.add({
                targets: `#${twoPathId}`,
                fillOpacity: [0, 1],
            }, '-=500')

            timeline.add({
                targets: `#${dashPathId}`,
                strokeDashoffset: [anime.setDashoffset, 0],
            }, '-=1500')
            timeline.add({
                targets: `#${dashPathId}`,
                fillOpacity: [0, 1],
            }, '-=1000')
            timeline.add({
                targets: `#${gPathId}`,
                strokeDashoffset: [anime.setDashoffset, 0],
            }, '-=1000')
            timeline.add({
                targets: `#${gPathId}`,
                fillOpacity: [0, 1],
            }, '-=500')

            animation.current = timeline;
        }
    })

    useEffect(() => {
        if (inViewport && isAnimateEnabled) {
            animation.current && animation.current.play()
        }
    }, [inViewport])

    return (
        <div ref={ref} onMouseEnter={() => {
            if (animation.current && animation.current.completed && !animation.current.reversed) {
                animation.current && animation.current.reverse()
                animation.current && animation.current.play()
            }

        }} onMouseLeave={() => {
            if (animation.current && animation.current.reversed) {
                animation.current.reverse()
                animation.current.play()
            }
        }}>
            <SvgIcon
                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" space="preserve"
                {...props}
            >
                {isGVisible && <path id={gPathId} fill={props.color || Colors.primaryAccent || theme.palette.primary.main} fill-opacity={isAnimateEnabled ? "0" : "1"} stroke={props.color || Colors.primaryAccent || theme.palette.primary.main} stroke-opacity={isAnimateEnabled ? '0': '1'} d="M431.9,317.9c5-0.9,11.5-5.7,16.4-12.9c5.4-8,8.6-18.2,8.7-28.5c0.1-7.1-2-15-7.3-24.4
	c-3.6-6.5-11.7-13.4-18.9-17c-9.3-4.7-14.8-5-18.8-5.4c-9.3-0.9-30.4,0.6-50.7,5.1c-19.5,4.3-46.4,16.6-59.6,25.8
	c-13.2,9.1-38.2,28.4-54.1,50.1c-13.1,17.9-14.7,21.4-21.3,35.6c-5.3,11.3-11.3,28.1-10,51.5c0.4,7.2,4,22,10.1,30.5
	c6,8.5,14.6,19.3,29.2,29.1s30.6,13.9,45.1,15.7c20.4,2.6,48.4,2.1,71.5-4.8c21.6-6.4,41.6-13.2,60.8-26.8
	c20-14.1,27.9-25.3,31.3-34.4c3.4-9.1,5.8-16.5,4.4-26.8c-1.4-10.4-4.8-13.7-7.7-16.8c-2.9-3-8.5-7.5-8.5-7.5s1.1-3.2,3.9-7
	c1.3-1.8,4.5-7,2.5-8.7c-0.9-0.8-2.5,1.1-4.4,2.4c-1.9,1.3-15.6,10.5-24.4,13.6c-8.8,3.1-19.6,7.3-36.1,7.1c-5.1-0.1-9-1.9-13.5-0.7
	c-1.9,0.5-3.9,0.8-6.4,5.2c-1.4,2.6,0,7,3.8,8.9c4.3,2.2,7.9,3.9,18.1,2.9c10.2-1,16.7-1.7,22.9-3.6s19.3-7.6,19.3-7.6
	s1.3-0.5,2.3,0.2c3,2.2,11,7.9,12.1,15.6c1.1,7.8,0.7,16.5-9,27.9c-9.7,11.4-18.8,22.8-43,34.9c-23,11.5-50.8,15.7-68.8,15.7
	c-26.5,0-39.9-1.2-54.9-7.1c-14.7-5.8-22.8-11.4-31.1-20c-9-9.3-12.9-19.4-14.9-24.8c-1.6-4.5-2.2-11.6-2.4-14.8
	c-0.6-14.7,6.1-31.8,8.3-37.1c6.8-16.6,18.8-34.5,32.6-50.1c7.5-8.5,18.1-18,24.9-22.9c6.8-4.9,20.5-15.1,36.6-22.6
	c8.1-3.8,25.1-8.9,40-12.4c16.6-3.9,36.8-5.2,46.7-2.2c6.8,2,18.7,8,22.2,19.6c1.8,5.9,3.9,12.4-0.6,21.2
	c-7,13.6-9.9,12.3-12.7,16.5c-2.8,4.2-3,7-1.9,9.2C426.2,318.9,431.9,317.9,431.9,317.9z" />}
                {isOVisible && <path id={oPathId} fill={props.color || Colors.secondaryAccent || theme.palette.primary.main} fill-opacity={isAnimateEnabled ? "0" : "1"} stroke={props.color || Colors.failure || theme.palette.primary.main} stroke-opacity={isAnimateEnabled ? '0': '1'} className="st1"  d="M82.8,162.8c-5.8,7.2-40,41-40.8,95.4c-0.2,15.7,1.5,33.3,7,51.4c5.5,18.2,14.4,37.4,32.8,56.2
	c12.3,12.6,31.3,27.3,60.2,38.6c23.1,9,52.3,12.9,73.8,9.4c4.9-0.8,13.9-2.2,16-3c0,0,15.1-3.7,25-9c1.1-0.6,11.8-5.8,18.6-11.6
	c5.3-4.5,9.8-8.5,14.1-13.4c7.3-8.2,16.7-20.7,21.5-31.6c3.7-8.4,5.7-21.1,6.1-26.5c1-16.1,0.5-29.9-2.3-43.9c-2-10-3-17-4-20
	c-0.4-1.3-3-2-6-1c-2.1,0.7-2.2,4-2,6c0.3,3,2.6,10.6,3.3,18.4c1,12.1,1,27.5-2.8,42.6c-2.6,10.5-7.6,22.7-12.5,30
	c-2.9,4.3-7.4,10.8-13,16.5c-6.5,6.7-14.3,12.5-19,15.5c-8,5-15,9-28,13c-3,0.9-7.4,2.3-18.4,3.4c-3.2,0.3-15.1,1.2-23.3,0.6
	c-5.3-0.4-12-1.4-17.3-2.2c-9.8-1.5-21.2-5.3-31.2-9.5c-5.1-2.2-11.2-5-14.9-7c-8.8-4.8-18.7-10.7-32-23.2
	c-14.7-13.8-24.8-30.6-31-48c-7-19.8-9.1-40-7-57c4.8-38,21.2-61.8,34-76c24.7-27.3,55-30,63-31c11.9-1.5,24.4-0.5,36,2
	c17.6,3.7,34.1,12,47.8,21c15,9.9,37.8,33.3,42.5,39.7c4.7,6.3,10,10.7,11.8,15c1.9,4.3,7.2,5,9.4,3.5c3.4-2.2,4.5-4.2,1.7-9.8
	c-4.2-8.4-13.2-21.4-24.9-34.1c-8.9-9.7-26-24.2-43.3-34.6c-21.1-12.7-43.1-17.7-56.1-18.7c-27-2.1-38.9,2.3-49,5
	C109.8,139.8,86.8,157.8,82.8,162.8z"/>}
                {is2Visible && <path id={twoPathId} fill={props.color || Colors.primary || theme.palette.primary.main} fill-opacity={isAnimateEnabled ? "0" : "1"} stroke={props.color || Colors.primary || theme.palette.primary.main} stroke-opacity={isAnimateEnabled ? '0': '.5'} className="st2" d="M254.4,156.8c0-2.2,0-4.5,0.1-6.7c1.2-23.2,5.1-46,12.2-68.3c1.7-5.4,4.1-10.5,7.3-15.2
	c7.5-10.9,17.3-14.4,30.1-10.7c10.6,3.1,19.2,9.3,26.8,17c13.2,13.5,21.6,30.9,25,45.5c3.4,14.7,5.4,32.1,2.9,55.2
	c-0.4,3.6-1.3,8.4-4.6,25.9c-1.2,6.5-7.7,29.7-9,34.2c-1.3,4.5-12.2,41.8-15.4,54.1c-3.2,12.3-5.6,30.1-6.6,42.7s0.8,27.8,2.3,37.2
	c1.5,9.5,6.4,17.1,7.4,18.5c0,0,2.5,3,5,0.8c1.4-1.2,1.4-1.9,1.9-3s2.4-7.3,3.6-10.8c1.1-3.4,3.7-12.3,4.7-16.7
	c1.3-5.2,4.6-17.1,10.9-35c5.3-15.2,14.7-31.4,25.4-50.2c9-15.7,15.6-24.5,18.6-28.7c11.7-16.6,33.2-47.3,33.9-48.6
	c0.8-1.2,2.6-3.5,3-5.3c0.4-1.8-2.4-3.4-4.5-3.7s-4.2,3-5.2,4.1c-1,1.1-9.1,10.2-12.5,14.1c-3.4,3.9-17.2,20.7-19.6,23.7
	c-2.4,3-23.3,32.8-26.3,37.5c-2.9,4.7-19.2,36-21,40c-1.7,4-10,26-10,26s1.5-14.7,2.3-19s8.1-37.8,10.1-45.5s7.3-28.1,9.1-34.6
	c4.5-16.3,7.3-32.6,8.1-38.2c1.5-11.1,2.7-20.3,3-24.9c1-17.7,0.1-35.2-4.3-52.4c-5.4-21.3-15.3-41.1-30.9-56.7
	c-7.7-7.7-16.2-14.2-26.4-18.1c-8-3.1-16.3-4.5-24.9-2.9c-9.7,1.9-17.1,7.5-23.3,15c-6.2,7.5-10,16.3-13.4,25.3
	c-6.5,17.4-9,37.6-10.4,52.9c-0.3,3.7-0.9,10.4-1.1,14.2c-0.1,1.8-0.1,3.6-0.1,5.5c0,3.2,0.1,11.3,0,13.5c-0.2,3.2,0.8,16.9,1,20.5
	c0.3,3.6,1.1,21.6,5,35.1c3.9,13.6,6,17.3,11.2,24c9.3,11.8,20.8,13.6,27.1,13.2c4.3-0.3,12.7-1.2,17.9-7.7
	c4.9-6.1,6.3-14.4,5.2-20.7c-1-5.3-2-9.7-3.7-11.8c-2.1-2.6-4.5-3-6.2-2.7c-1.7,0.3-7.3,2-5.2,8.2c0.6,1.7,0.9,2.4,2.4,5.6
	c2.5,5.4,1.3,13.5-3.7,16.2c-5.9,3.1-11.7,1-16-1.4c-4.8-2.5-8.4-7.4-10.9-12.9c-1.9-4.1-3.3-9-4.3-17.7c-1-8.7-2.7-20.1-3-25.4
	C254.8,183,254.5,164.5,254.4,156.8z"/>}
                {is2Visible && <path id={dashPathId} fill={props.color || Colors.primary || theme.palette.primary.main} fill-opacity={isAnimateEnabled ? "0" : "1"} stroke={props.color || Colors.primary || theme.palette.primary.main} stroke-opacity={isAnimateEnabled ? '0': '.5'} className="st2" d="M394,186.4c0.6-2.9,1-5.8,1.8-8.6c3.3-12,8.4-23.2,15.3-33.6c0.7-1,1.9-2,3-2.4c3.2-0.9,5.7,1.8,4.8,5.2
	c-1.5,5.6-3.1,11.2-4.7,16.7c-1.7,5.8-3.4,11.6-5,17.5c-1.6,5.9-4.6,11.1-8.2,15.9c-1.3,1.7-3.1,1.6-3.9-0.2
	C395.5,193.7,394.1,190.3,394,186.4z"/>}
            </SvgIcon>
        </div>
    );
}

export default O2GFullLogo
