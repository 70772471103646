import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Colors from './Colors'

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: Colors.primary,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            contrastText: '#ffffff',
            text: {
                primary: Colors.textSelected, // Text Selected
                secondary: Colors.textPassive, // Text passive
                disabled: Colors.textSelected, // Text passive
            }
        },
        secondary: {
            light: Colors.secondary,
            main: Colors.secondary,
            // dark: will be calculated from palette.secondary.main,
            contrastText: Colors.textSelected,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

export default theme
