import React, {useRef, useEffect, useState} from 'react'
import { SvgIcon } from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

import Colors from '../../Constants/Colors'

const O2GFullLogoNegative = (props = {}) => {
    const { isAnimateEnabled, strokeOpacity = "1", isOVisible = true, is2Visible = true, isGVisible = true } = props
    const ref = useRef(null);
    let animation = useRef(null);
    const theme = useTheme();
    const [randId] = useState(Math.floor(Math.random() * 100000))
    const oPathId = `oPathId-${randId}`;
    const twoPathId = `twoPathId-${randId}`;
    const dashPathId = `dashPathId-${randId}`;
    const gPathId = `gPathId-${randId}`;

    const pathStyle = {
        stroke: props.color || theme.palette.primary.main,
        strokeWidth: 7.4647,
        strokeMiterlimit: 10
    }

    return (
        <SvgIcon
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 375.2 324.9" style="enable-background:new 0 0 375.2 324.9;" space="preserve"
            {...props}
        >
            <g>
                <path style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} d="M281.4,324.9H93.8l-0.3-0.5L0,162.4l0.3-0.5L93.8,0h187.6l0.3,0.5l93.5,161.9l-0.3,0.5L281.4,324.9z M94.9,322.9h185.3
		l92.6-160.4L280.2,2H94.9L2.3,162.4L94.9,322.9z"/>
                <g>
                    <polygon style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} points="290.9,139 290.9,139 280,157.4 235.9,157.4 219,186.6 214,177.9 169.2,177.9 174.4,168.8 196.6,168.8
			203.3,157.2 214,138.6 219.5,129.1 214.2,119.8 279.9,119.8 		"/>
                    <polygon style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} points="163.4,149.6 140.8,189.5 144.6,196.1 81.8,196.1 37.7,119.7 81.8,43.3 170,43.3 214,119.5 168.4,119.5
			146.5,81.5 102.3,81.5 80.3,119.7 102.3,157.9 146.5,157.9 162.9,129.5 168.2,138.6 192,138.6 185.7,149.6 		"/>
                    <polygon style={pathStyle} fill={props.color || Colors.primary || theme.palette.primary.main} points="334.5,213.5 334.5,213.5 300.4,272.6 212.1,272.6 168.5,197 214.6,197 235.7,233.6 235.9,233.8 280,233.8
			290.7,215.3 269.6,215.3 258.6,196.2 269.6,177.1 291.3,177.1 313.2,176.9 323.3,194.1 323.7,194.9 		"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default O2GFullLogoNegative
