import React, { useEffect, useState } from "react";
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';

import {Typography} from "@material-ui/core";
import useSceneStyles from "../../Styles/Scene";
import {useScreenSize} from "../../Providers/ScreenSizeProvider";

const PageTitle = (props) => {
    const {
        style = {},
        titleColor,
        subtitleColor,
        titleText,
        subtitleText,
        RightIcon
    } = props;
    const { isSmall, isXS } = useScreenSize();

    const sceneClasses = useSceneStyles();

    const windowHeight = window.innerHeight - 64;
    const windowWidth = window.innerWidth - 64;

    let titleSize = "h2";
    if (isXS) {
        titleSize = "h4"
    } else if (isSmall) {
        titleSize = "h2"
    }

    return (
        <React.Fragment>
            <div className={clsx(sceneClasses.row)} style={style}>
                <div className={clsx(sceneClasses.column)}>
                    <div className={clsx(sceneClasses.row)} style={{ flexDirection: isXS ? 'column' : 'row' }}>
                        <div style={{ margin: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography style={{ color: titleColor }} color={titleColor ? 'null' : "textPrimary"} variant={titleSize}>
                                {titleText}
                            </Typography>
                            <Typography style={{ color: subtitleColor }} color={subtitleColor ? 'null' : "textSecondary"} variant={"h6"}>
                                {subtitleText}
                            </Typography>
                        </div>
                    </div>
                </div>
                {RightIcon &&
                    <RightIcon />
                }
            </div>
        </React.Fragment>
    );
};

export default PageTitle;
