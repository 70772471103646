export default {
    // Zack's fav
    primary: '#bf90ec',
    primaryBackground: '#cc527b',
    primaryAccent: '',
    primaryAccentBackground: '#56a1d7',
    secondaryAccent: '#5d3278',
    secondaryAccentBackground: '#a974cb',

    // Mike's fav
    // primary: '#6c86d0',
    // primaryAccent: '#995775',
    // secondaryAccent: '#8269ad',

    // primary: '#0265ad',
    // primaryAccent: '#0265ad',
    // secondaryAccent: '#513990',

    secondary: '#f9f7f5',
    textPassive: '#878787',
    textSelected: '#303030',
    inputBackground: '#E3E3E3',
    widgetBackground: '#F2f2f2',
    success: '#8DC63F',
    failure: '#ED1C24',
    disabled: '#D8D8D8',
}
