import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {RoutingProvider} from "./Providers/RoutingProvider";
import ApplicationUI from "./Scenes/ApplicationUI";
import Theme from './Constants/Theme'
import {
    BrowserRouter as Router,
} from "react-router-dom";

import {ScreenSizeProvider, useScreenSize} from "./Providers/ScreenSizeProvider";
import {HouseProvider} from "./Providers/HouseProvider";
import Themes from './Constants/Themes';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function AppComponent() {
    let { theme = 'basic' } = useScreenSize();

    let themeToUse = Themes.find((themeObj) => themeObj.key === theme) || Themes[0];

    return (
        <ThemeProvider theme={themeToUse.Theme}>
            <Router>
                <RoutingProvider>
                    <HouseProvider>
                        <ApplicationUI />
                    </HouseProvider>
                </RoutingProvider>
            </Router>
        </ThemeProvider>
    )
}

function App() {
  return (
      <ScreenSizeProvider>
          <AppComponent />
      </ScreenSizeProvider>
  );
}
export default App;
