import React, {useRef, useEffect, useState} from 'react'
import { SvgIcon } from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

import O2GFullLogo from './O2GFullLogo'
import O2GFullLogoNegative from './O2GFullLogoNegative'
import O2GFullLogoHex from './O2GFullLogoHex'

import Colors from '../../Constants/Colors'
import useOnScreen from '../../Helpers/useIntersection'
import anime from "animejs";

import {useScreenSize} from "../../Providers/ScreenSizeProvider";

const O2GFullLogoBasic = (props = {}) => {
    const { logo, updateLogo } = useScreenSize();
    const {
        isAnimateEnabled,
        strokeOpacity = "1",
        isOVisible = true,
        is2Visible = true,
        isGVisible = true,
        logoOverride
    } = props;
    const logoToUse = logoOverride || logo;

    const ref = useRef(null);
    let animation = useRef(null);
    const theme = useTheme();
    // const inViewport = useOnScreen(ref, { updateOnce: true });
    const [randId] = useState(Math.floor(Math.random() * 100000))
    const oPathId = `oPathId-${randId}`;
    const twoPathId = `twoPathId-${randId}`;
    const dashPathId = `dashPathId-${randId}`;
    const gPathId = `gPathId-${randId}`;


    const pathStyle = {
        fill: props.color || theme.palette.primary.main,
        // strokeWidth: 1,
        // strokeMiterlimit: 1
    }

    if (logoToUse === 'negative') {
        return (
            <O2GFullLogoNegative {...props} />
        )
    }

    if (logoToUse === 'original') {
        return (
            <O2GFullLogo {...props} />
        )
    }

    if (logoToUse === 'hex') {
        return (
            <O2GFullLogoHex {...props} />
        )
    }

    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 507.16 686.58"
            shape-rendering="geometricPrecision"
            {...props}
        >
            <g id="Layer_1">
                <path style={pathStyle} shape-rendering="geometricPrecision" d="M423.18,65.06C319.07-28.6,158.74-20.13,65.07,83.97-28.6,188.09-20.13,348.42,83.98,442.08c104.11,93.67,264.44,85.2,358.11-18.91,15.16-16.85,27.64-35.17,37.48-54.44,10.29-20.16,17.67-41.35,22.2-62.99,17.89-85.53-8.95-178.03-78.59-240.68Zm45.48,169.09c-4.55-9.6-16.64-39.87-43.57-68.63-43.1-46.01-106.98-68.84-170.54-56.12-37.72,7.56-62.29,23.31-87.38,51.2-46.84,52.06-50.46,140.41,1.61,187.24,52.05,46.83,132.22,42.6,179.05-9.46,8.23-9.15,14.98-19.03,20.21-29.36-20.68,6.48-44.15,2.18-61.42-13.36-26.03-23.41-28.15-63.49-4.73-89.53,22.18-24.66,59.33-27.86,85.32-8.2,10.92,7.26,20.93,18.59,29.03,37.39,29.92,69.44,5.02,143.88-23.4,176.51-64.88,74.47-196.2,87.11-286.73,5.66C15.58,336.04,8.21,196.63,89.66,106.1,171.11,15.57,315.43,10.94,401.07,89.65c63.02,57.93,69.21,114.87,67.59,144.5Z"/>
            </g>
            <g id="Layer_2">
                <g>
                    <path style={pathStyle} shape-rendering="geometricPrecision" d="M77.12,532.36c-42.59,0-77.12,34.52-77.12,77.1s34.53,77.11,77.12,77.11,77.11-34.52,77.11-77.11-34.53-77.1-77.11-77.1Zm0,115.66c-21.3,0-38.55-17.27-38.55-38.56s17.25-38.55,38.55-38.55,38.55,17.27,38.55,38.55-17.27,38.56-38.55,38.56Z"/>
                    <path style={pathStyle} shape-rendering="geometricPrecision" d="M491.1,611.98c7.93,0,10.32,2.84,10.17,10.52-.16,8.79,.3,26.89-1.46,30.36-.43,.85-1.08,1.56-1.64,2.33-.28,.38-.56,.76-.85,1.14s-.58,.75-.87,1.12c-.29,.37-.59,.74-.89,1.1-1.21,1.48-2.48,2.92-3.8,4.3-.31,.34-.63,.67-.96,.99l-.78,.78c-.41,.41-.83,.81-1.25,1.21h-.01c-.42,.4-.84,.79-1.27,1.18-.44,.39-.88,.78-1.33,1.16-.44,.38-.89,.76-1.34,1.13-1.81,1.49-3.7,2.9-5.65,4.22-.47,.32-.94,.64-1.42,.94-.98,.64-1.97,1.25-2.99,1.84-.5,.3-1.01,.59-1.53,.87-1.02,.57-2.06,1.12-3.12,1.64-.65,.32-1.3,.63-1.95,.93-.39,.19-.78,.36-1.17,.53-.37,.17-.75,.33-1.12,.48-.44,.19-.89,.38-1.34,.56-.02,.01-.04,.02-.06,.02-.44,.18-.89,.35-1.33,.52-.89,.34-1.79,.66-2.7,.97-.04,.01-.07,.02-.1,.03-.34,.12-.69,.23-1.04,.34-.91,.29-1.82,.56-2.75,.82-.59,.16-1.18,.32-1.78,.47-.58,.15-1.17,.29-1.75,.42-.03,.01-.07,.01-.1,.02-.49,.11-.99,.22-1.48,.32-1.76,.36-3.54,.66-5.35,.9-.4,.05-.81,.1-1.22,.15-.98,.12-1.97,.21-2.97,.29h-12.12c-13.2-1.02-25.46-5.36-35.96-12.19-21.19-13.76-35.21-37.65-35.21-64.8,0-42.65,34.58-77.23,77.23-77.23,3.28,0,6.51,.2,9.68,.6,18.81,2.35,35.52,11.47,47.61,24.84,3.81,4.21,3.98,9.24,3.66,13.37-.44,5.66-3.07,11.1-8.45,14.29-5.7,3.37-12.34,5.31-19.45,5.31-.38,0-.75,0-1.13-.02-.16,0-.33-.01-.5-.02-6.36-16.27-13.51-18.92-29.49-19.7-.64-.03-1.28-.05-1.93-.05-21.32,0-38.61,17.28-38.61,38.61,0,19.68,14.72,35.93,33.75,38.32,3.24,.5,13.17,.98,17.63-.3,4.53-1.3,12.81-4.32,12.96-15.04,.17-12.99-11.72-8.98-11.72-10.25,.03-7.85,.34-10.3,11.16-10.5,7.48-.14,22.39,.17,30.1,.17Z"/>
                    <path style={pathStyle} shape-rendering="geometricPrecision" d="M317.49,686.13h-97.03c-20.12,0-30.39-25.17-16.42-39.66l25.39-16.7c9.3-7.39,25.65-15.24,35.01-25.88,.46-.52,.87-1.04,1.24-1.57,.01-.01,.02-.02,.02-.03,3.43-3.47,5.54-8.23,5.54-13.49,0-.74-.04-1.48-.13-2.2-.12-1.07-.32-2.11-.62-3.12-2.3-8.03-9.7-13.91-18.47-13.91-5.02,0-9.59,1.92-13.01,5.07-3.07,2.82-5.22,6.63-5.95,10.92-.18,1.05-.27,2.14-.27,3.24,0,.72,.04,1.44,.12,2.14h-17.68c-12.24,0-20.92-12.31-16.43-23.7,.03-.07,.06-.14,.08-.21,2.86-7.18,6.88-13.36,12.06-18.54,5.18-5.17,11.32-9.16,18.43-11.95,7.1-2.79,14.91-4.18,23.41-4.18s16.11,1.32,23.21,3.98c7.11,2.66,13.25,6.38,18.44,11.16,5.18,4.78,9.23,10.46,12.15,17.03,2.92,6.58,4.38,13.72,4.38,21.42,0,8.24-1.76,15.84-5.28,22.82-2.51,4.98-6.09,10.04-10.74,15.2-3.56,3.94-9.63,7.42-15.97,11.27-6.34,3.85-25.78,12.34-25.78,12.34h25.75c10.64,0,20.28,4.31,27.26,11.29,6.98,6.98,11.29,16.62,11.29,27.26Z"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default O2GFullLogoBasic
