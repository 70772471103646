import { useState, useEffect } from 'react'

function useOnScreen(ref, { updateOnce }) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => {
            (!updateOnce || entry.isIntersecting) && setIntersecting(entry.isIntersecting)
        }
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}

export default useOnScreen
